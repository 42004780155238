import logo from './logo.svg';
import './App.scss';
import Hero from './elements/Hero';
import Partners from './elements/Partners';
import NavBar from './elements/Navbar';
import Features from './elements/Features';
import Offering from './elements/Offering';
import Footer from './elements/Footer';
import Pricing from './elements/Pricing';
import FAQ from './elements/FAQ';
import EmailBanner from './elements/EmailBanner';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Hero />
      {/* <Partners /> */}
      <Features />
      <Pricing />
      <FAQ />
      {/* <Offering /> */}
      <EmailBanner />
      <Footer />
    </div>
  );
}

export default App;
