import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Define the FAQ component
const FAQ = () => {
  // Array of FAQ items, each containing a question and an answer
  const faqItems = [
    {
      question: 'What distinguishes EchoFlow from other IVR solutions in the market?',
      answer: `Through human-like interactions, EchoFlow is able to direct your call directly. It is also able to be embedded in your system, making the call-center entirely redundant.`,
    },
    {
      question: 'Can I keep my current phone number?',
      answer: `We can make sure you keep your current phone number so that you don't have to adjust your communication.`,
    },
    {
      question: 'Can EchoFlow be seamlessly integrated with my existing CRM system?',
      answer: `Yes, through our API connection, we can embed EchoFlow in your system. We have the ambition to build integrations with popular CRM systems.`,
    },
    {
      question: 'How secure is the EchoFlow platform?',
      answer:
        'EchoFlow is built on the Google Cloud Platform, ensuring a robust and secure infrastructure. Our commitment to data security includes access controls and regular security audits. We prioritize the protection of customer data and maintain a secure environment to safeguard against potential threats.',
    },
    {
        question: 'What languages does EchoFlow support?',
        answer:
        'We offer 72 languages: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.',
    },
    // Add more FAQ items as needed
  ];

  // Calculate the number of FAQ items per column
  const itemsPerColumn = Math.ceil(faqItems.length / 3);

  // Split FAQ items into three columns
  const columns = Array.from({ length: 3 }, (_, columnIndex) =>
    faqItems.slice(columnIndex * itemsPerColumn, (columnIndex + 1) * itemsPerColumn)
  );

  return (
    <div id="FAQ" className="py-md-5 px-3 mt-5">
      <Container className="py-md-5 my-3">
        <div className="display-2">Frequently asked questions</div>
        <div className="display-4 mt-3 mb-5">
          If you can’t find what you’re looking for, email our support team and we'll get back to you.
        </div>
        <Row>
          {/* Map through columns and render FAQ items in three columns */}
          {columns.map((column, columnIndex) => (
            <Col key={columnIndex} lg={4} style={{ borderRadius: "50px" }}>
              {column.map((item, index) => (
                <div key={index} className="mb-5">
                  <h5>{item.question}</h5>
                  <div className="faq-text">{item.answer}</div>
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
export default FAQ;
