// Import necessary dependencies
import React from 'react';
import { Stack, Button } from 'react-bootstrap';
import { HiPlay } from "react-icons/hi2";
import EmailInput from './EmailInput';

// Define the Hero component
const Hero = () => {
  return (
    <div className="jumbotron p-3 d-flex flex-column justify-content-center align-items-center hero-height mb-5">
      <div className='col col-lg-6 pt-5 d-flex flex-column justify-content-center align-items-center text-center'>
        <h1 className="display-1">AI-powered <div className='text-primary d-inline'>human voices</div> in your call center</h1>
        <p className="display-4 w-75 mt-4">Discover the future of interaction with our AI-powered IVR bot. Integrated, affordable quality, and in one powerful solution.</p>
        <Stack direction={'horizontal'} gap={4} className='mx-auto mt-5'>
          <Button className='rounded-pill btn-black' href="https://calendly.com/audioflow/call2flow-demo" target='_blank'>Book a demo</Button>
          {/* <Button className='rounded-pill btn-transparant d-flex flex-row justify-content-center align-items-center'><HiPlay className='text-primary'/>&ensp;<div>Watch video</div></Button> */}
          <EmailInput variant={'dark'}/>
        </Stack>
      </div>
  </div>
  );
};

// Export the Hero component
export default Hero;