import React from 'react';
import { Container, Nav, Navbar, Button } from 'react-bootstrap';
// import {useNavigate} from "react-router-dom";

const NavBar = () => {
    // const navigate = useNavigate();
  return (
    <header className="py-10 sticky-top bg-white">
      <Container className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Navbar expand="md" variant="light">
            <Navbar.Brand role='button' className='d-flex align-items-center'>
                <img
                    alt="Call 2 Flow"
                    src="../logo.svg"
                    width="48"
                    height="48"
                    className="d-inline-block align-top pe-3"
                    />
                    <>
                      Call<div className='text-primary d-inline font-weight-bold'>2</div>Flow
                    </>
            </Navbar.Brand>
          <Navbar.Toggle aria-label="Toggle Navigation" />
          <Navbar.Collapse className="justify-content-between">
            <Nav className="d-flex align-items-center px-5 gap-md-4">
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
              <Nav.Link href="#FAQ">FAQs</Nav.Link>
            </Nav>
            <Nav className="d-flex align-items-center gap-3 gap-md-4">
              {/* <Nav.Link href="" className="hidden d-inline-block">
                Sign in
              </Nav.Link> */}
              <Button className="hidden d-inline" href="https://calendly.com/audioflow/call2flow-demo" target='_blank'>Book a demo</Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default NavBar;
