// Import necessary dependencies
import React, {useState} from 'react';
import { Container, Col, Row, Carousel } from 'react-bootstrap';
import './Features.scss';

import apiImage from './img/Bot.png';
import availabilityImage from './img/Bot.png';
import multilingualImage from './img/List.png';

import { ReactComponent as ChipIcon } from './svg/chip.svg';
import { ReactComponent as ClockIcon } from './svg/clock.svg';
import { ReactComponent as LanguageIcon } from './svg/g-translate.svg';

// Define the Features component
const Features = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const items = [
    {
      title: 'Integrated Business Solution',
      subtitle: 'Embedding Excellence into Your Workflow',
      description: "Experience the transformative power of our seamlessly integrated solution. Enjoy streamlined processes and heightened efficiency as it becomes an integral part of your business system.",
      icon: (<ChipIcon
        className="feature-icon w-100 h-100"
        alt="Integrated Business Solution"
      />),
      image: apiImage,
    },
    {
      title: 'Operationality',
      subtitle: "Ensure Operational Continuity Around the Clock",
      description:"Optimize operations with our 24/7 solution, ensuring seamless efficiency around the clock. Thrive in a dynamic business landscape with constant operational support.",
      icon: (<ClockIcon
        className="feature-icon w-100 h-100"
        alt="Operationality"
      />),
      image: availabilityImage,
    },
    {
      title: 'Multilingual Support',
      subtitle: 'Communicate seamlessly with callers from around the world.',
      description: 
      "Enhance global communication with our versatile IVR bot. Speak confidently, as we support 72 languages, eliminating barriers and connecting you with a global user base.",
      icon: (<LanguageIcon
        className="feature-icon w-100 h-100"
        alt="Multilingual"
      />), // Add the appropriate icon for multilingual support
      image: multilingualImage, // Add the appropriate image for multilingual support
    }
  ];

  return (
    <div id="features" className='jumbotron pb-md-5 mb-5' >
    <Container className='pt-md-4 my-3  text-center'>
      <div className='display-2 text-black'>Experience Operational Excellence.</div>
      <div className='display-4 mt-3'>Streamline and Simplify Your Business Workflow Effortlessly
    with Our Comprehensive and Innovative Integrated Solution.</div>
    </Container>
    <Container className='overflow-hidden mb-5'>
      <Row>
          {items.map((item, i) => (
            <Col lg={4} md={12}>
              <div key={i} onClick={() => handleItemClick(i)} className='my-lg-5 my-4 mx-lg-2'>
                <ListedFeature item={item} selected={i === selectedItem ? true : false}/>
              </div>
            </Col>
          ))}
      </Row>
      <Row className='d-flex flex-row justify-content-center'>
        <div style={{ overflow: 'hidden' }}>
          <img
            src={items[selectedItem].image}
            alt={items[selectedItem].title}
            className="border border-gray pt-4"
            style={{borderRadius: '20px',  width: '100%', objectFit: 'cover' }}
          />
        </div>
      </Row>
    </Container>
  </div>
  );
};

const ListedFeature = ({item, selected}) => {
  return(
    <div role="button" className={'feature'}>
      <div className={`p-1 feature-icon-container rounded d-inline-flex align-items-center justify-content-center ${selected? 'bg-primary active' : 'bg-gray'}`} style={{ width: '36px', height: '36px' }}>
        {item.icon}
      </div>
      <h5 className={`mt-lg-3 mt-2 ${selected? 'text-primary' : ''}`}>{item.title}</h5>
      <h4 className={`mt-lg-3 mt-2 ${selected? 'text-black' : ''}`}>{item.subtitle}</h4>
      <div className={`mt-lg-3 mt-2 description ${selected? 'text-black' : ''}`}>{item.description}</div>
    </div>
  )
}

// Export the Hero component
export default Features;