// Import necessary dependencies
import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import TextInput from './TextInput';
import EmailInput from './EmailInput';

// Define the Hero component
const EmailBanner = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const addContact = async (email) => {
    const link = 'https://call2flow-email-api.vercel.app/api/addEmail?email=' + email;
    
    try {
      const response = await fetch(link, {  // Enter your IP address here
        method: 'GET',
        mode: 'cors',
     })
     console.log(response)
      if (response.ok) {
        setSuccessMessage("Subscribed! Please check your email for updates");
      } else {
        throw new Error('Failed to add contact');
      }
    } catch (error) {
      setSuccessMessage("Email already in use. Please try another email");
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addContact(email);
  };

  return (
    <div className='py-3 py-md-5 mt-5 bg-dark'>
      <Container>
        <Row className="d-flex flex-row mt-md-4 py-4 mx-0 px-0 text-white">
          <Col md={6} className="align-items-center">
            <h1>Get notified when we’re launching.</h1>
            <div className='subtitle'>Sign up for our newsletter.</div>
          </Col>
          <Col md={1} />
          <Col md={6} className="d-flex flex-column justify-content-center">
            <EmailInput variant={'white'}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// Export the Hero component
export default EmailBanner;