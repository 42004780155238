// Import necessary dependencies
import React from 'react';
import { Container, Row, Col, ListGroup, Stack } from 'react-bootstrap';
import { HiCheckCircle } from 'react-icons/hi2';

// Define the Pricing component
const Pricing = () => {
  // List of pricing options
  const pricingOptions = [
    {
      tier: 'Starter',
      description: 'Good small-scale deployments.',
      cost: '$25',
      unit: '/100 calls',
      storage: '50MB (Basic)',
      customFunctions: 'Call forwarding',
      languageSupport: '1 language',
      performanceAnalytics: 'Basic analytics features',
      crmAndIntegrations: 'Limited integrations',
      onboarding: 'Standard onboarding process',
      supportLevel: 'Priority email support',
    },
    {
      tier: 'Small business',
      description: 'Perfect for small / medium sized businesses.',
      cost: '$40',
      unit: '/100 calls',
      storage: '1GB (Enhanced)',
      customFunctions: 'Call forwarding',
      languageSupport: 'Multilanguage (72 languages)',
      performanceAnalytics: 'Advanced performance analytics',
      crmAndIntegrations: 'CRM integration and basic integrations',
      onboarding: 'Dedicated onboarding support',
      supportLevel: 'Priority email support',
    },
    {
      tier: 'Enterprise',
      description: 'For enterprise type customers with specific wishes.',
      cost: 'Custom',
      storage: 'Unlimited (High-capacity)',
      customFunctions: 'Advanced custom functions',
      languageSupport: 'Multilanguage (72 languages)',
      performanceAnalytics: 'Comprehensive performance analytics',
      crmAndIntegrations: 'CRM integration',
      onboarding: 'Dedicated onboarding consultation',
      supportLevel: 'Dedicated account manager',
    },
  ];

  return (
    <div id="pricing" className='bg-dark py-md-5 mt-5'>
      <Container className='py-5 my-3 text-white'>
        <div className='text-center'>
          <div div className='display-2 text-white'>Pricing that fits your business.</div>
          <div className='display-4 mt-3 text-white mb-5'>We offer specialized prices that best fit your business needs.</div>
        </div>
        <Row className='m-md-0 m-3'>
          {pricingOptions.map((option, index) => (
            <PricingItem {...option} length={pricingOptions.length} highlighted={index === 1 /* Set to true for the second item, you can adjust this condition */} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

// Define the PricingItem component
const PricingItem = ({ length, tier, description, cost, unit, storage, customFunctions, languageSupport, performanceAnalytics, crmAndIntegrations, onboarding, supportLevel, highlighted }) => {
  const ListItem = ({children}) => (
    <div className='d-flex flex-row gap-2 align-items-center'><HiCheckCircle size={24}/>{children}</div>
  )
  return (
    <Col lg={12/length} className={`pricing-card p-5 ${highlighted ? 'bg-primary' : ''}`} style={{borderRadius: "50px"}}>
      <div className='pb-3 pe-0 pe-md-5'>
        <div className='d-flex flex-row align-items-baseline'>
          <h1>{cost}</h1>
          { unit && <div>{unit}</div> }
        </div>
        <h4>{tier}</h4>
        <h5 className='subtitle'>{description}</h5>
      </div>
      <Stack gap={3} variant="flush">
        <ListItem>{storage}</ListItem>
        <ListItem>{customFunctions}</ListItem>
        <ListItem>{languageSupport}</ListItem>
        <ListItem>{performanceAnalytics}</ListItem>
        <ListItem>{crmAndIntegrations}</ListItem>
        <ListItem>{onboarding}</ListItem>
        <ListItem>{supportLevel}</ListItem>
      </Stack>
    </Col>
  );
};

// Export the Pricing component
export default Pricing;