import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextInput.module.scss'; // Adjust the import path based on your project structure

const TextInput = ({ className, children, variant, placeholder, value, onChange, type }) => (
  <div className={`${styles.textInput} ${className} ${variant}`}>
    <div className='d-block'>{children}</div>
    <input
      type={type}
      className={`py-1 px-3 w-100 rounded border-gray text-${variant}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string
};

TextInput.defaultProps = {
  placeholder: 'Enter text',
  value: '',
  onChange: () => {},
  type: 'text',
  variant: 'primary'
};

export default TextInput;