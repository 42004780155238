// Import necessary dependencies
import React, {useState} from 'react';
import { Container, Col, Row, Carousel } from 'react-bootstrap';

// Define the Features component
const Logo = () => {

  return (
    <div className='d-flex flex-row align-items-center'>
        <img
            alt="Call 2 Flow"
            src="../logo.svg"
            width="48"
            height="48"
            className="d-inline-block align-top pe-3"
            />
            <>
              Call<div className='text-primary d-inline font-weight-bold'>2</div>Flow
            </>
    </div>
  );
};

export default Logo;