// Import necessary dependencies
import React, { useState } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import TextInput from './TextInput';

// Define the Hero component
const EmailInput = ({variant}) => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const addContact = async (email) => {
    const link = 'https://call2flow-email-api.vercel.app/api/addEmail?email=' + email;
    
    try {
      const response = await fetch(link, {  // Enter your IP address here
        method: 'GET',
        mode: 'cors',
     })
     console.log(response)
      if (response.ok) {
        setSuccessMessage("Subscribed! Please check your email for updates");
      } else {
        throw new Error('Failed to add contact');
      }
    } catch (error) {
      setSuccessMessage("Email already in use. Please try another email");
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addContact(email);
  };

  return (
    <form className={"d-flex flex-column align-items-stretch"} onSubmit={handleSubmit} id={"signUp"}>
        {successMessage ? <small className={`py-2 text-${variant}`}>{successMessage}</small> : <div>&ensp;</div>}
        <div className='d-flex flex-row gap-2 align-items-stretch justify-content-start'>
            <TextInput className='d-flex flex-grow-1' variant={variant} type={'email'} placeholder='Enter your work email' value={email} onChange={handleChange}/>
            <Button type="submit" className='rounded'>
                Subscribe
            </Button>
        </div>
        <small className='text-gray mt-2'>
            We care about your data. We will not share it with anyone else.
        </small>
    </form>
  );
};

// Export the Hero component
export default EmailInput;