// Import necessary dependencies
import React, {useState} from 'react';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import Logo from './Logo';

// Define the Features component
const Footer = () => {

  return (
    <Container className='py-md-5 py-3 my-3'>
      <Row>
        <Logo/>
      </Row>
      <hr/>
      <Row>
        <Col md={6}>
          <small>Copyright @ 2023 Call2Flow. All rights reserved.</small>
        </Col>
        <Col md={6} className='d-flex justify-content-md-end justify-content-sm-start mt-3 mt-md-0'>
          <Row className='d-inline'>
            <Col md={4} className='d-inline'>
              <a role='button' className='nav-link d-inline' href="#features">
                Features
              </a>
            </Col>

            <Col md={4} className=' d-inline'>
              <a role='button' className='nav-link d-inline' href="#pricing">
                Pricing
              </a>
            </Col>

            <Col md={4} className=' d-inline'>
              <a role='button' className='nav-link d-inline' href="#FAQ">
                FAQs
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

// Export the Hero component
export default Footer;